var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
		content: _vm.tooltip,
		placement: 'top',
		theme: 'light',
		arrow: true,
		onShow: () => _vm.tooltip !== undefined,
	}),expression:"{\n\t\tcontent: tooltip,\n\t\tplacement: 'top',\n\t\ttheme: 'light',\n\t\tarrow: true,\n\t\tonShow: () => tooltip !== undefined,\n\t}"}],staticClass:"material-card",class:[
		{ 'is-stacked': _vm.isStacked, 'is-clickable': _vm.clickable, 'animate-on-hover': _vm.animate },
		`is-${_vm.size}`,
	]},[_c('div',{staticClass:"material-card-image-container"},[_c('img',{attrs:{"src":_vm.image,"alt":"material image"}}),(_vm.hasTopRightSlot)?_c('div',{staticClass:"top-right"},[_vm._t("top-right")],2):(_vm.count !== null)?_c('div',{staticClass:"top-right"},[_c('BaseTag',{attrs:{"size":"medium","color":"transparent-dark"}},[_vm._v(" "+_vm._s(_vm.count)+" ")])],1):_vm._e()]),(_vm.progress !== undefined)?_c('BaseProgressBar',{staticClass:"my-3",attrs:{"progress":_vm.progress}}):_vm._e(),_c('p',{staticClass:"title is-6 mt-4"},[_vm._v(_vm._s(_vm.title))]),(_vm.description)?_c('p',{staticClass:"subtitle is-9",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }