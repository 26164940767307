var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"is-flex is-align-items-center py-4 mt-5 mb-5"},[_c('BaseIcon',{attrs:{"icon":"flame-2","color":"red","no-padding":"","has-transition":""}}),_c('div',{staticClass:"title is-5 ml-3"},[_vm._v(_vm._s(_vm.title))])],1),_c('BaseTabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.coursesDisplayed),function(course,index){return _c('BaseTab',{key:index.toString(),attrs:{"id":index.toString()},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(course.title))]},proxy:true}],null,true)})}),1),_c('BaseTabsContainer',{attrs:{"active":_vm.activeTab}},_vm._l((_vm.coursesDisplayed),function(course,index){return _c('BaseTabContent',{key:index.toString(),attrs:{"id":index.toString()}},[_c('div',{staticClass:"is-flex is-align-items-center mt-2 device-responsive-flex",class:[
					_vm.levelsUpper(_vm.courseAchievement(course).levels).length < 3
						? 'is-justify-content-center'
						: 'is-justify-content-space-between',
				]},[_c('div',{staticClass:"action px-2"},[_c('AchievementCard',{staticClass:"box is-rounded no-box-shadow achievement-card is-flex is-justify-content-center is-align-items-center",attrs:{"achievement":_vm.courseAchievement(course)}})],1),_vm._l((_vm.levelsUpper(_vm.courseAchievement(course).levels)),function(level,ind){return _c('div',{key:ind.toString(),attrs:{"id":ind.toString()}},[_c('div',{staticClass:"action"},[_c('FeaturedAchievementCard',{attrs:{"level":level,"link-image":_vm.courseAchievement(course)
									.featured_achievement_relation
									.link_image.url}})],1)])})],2),(_vm.levelsLower(_vm.courseAchievement(course).levels))?_c('div',{staticClass:"is-flex is-align-items-center mt-5 device-responsive-flex",class:[
					_vm.levelsLower(_vm.courseAchievement(course).levels).length < 4
						? 'is-justify-content-center'
						: 'is-justify-content-space-between',
				]},_vm._l((_vm.levelsLower(_vm.courseAchievement(course).levels)),function(level,ind){return _c('div',{key:ind.toString(),attrs:{"id":ind.toString()}},[_c('div',{staticClass:"action"},[_c('FeaturedAchievementCard',{attrs:{"level":level,"link-image":_vm.courseAchievement(course)
									.featured_achievement_relation
									.link_image.url}})],1)])}),0):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }