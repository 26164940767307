import { getSectionWithContext, getSectionSimple } from '@/api/section';

const state = {};

const getters = {};

const actions = {
	getSectionWithContext(_, sectionRelationId) {
		return getSectionWithContext(sectionRelationId);
	},
	getSectionSimple(_, sectionRelationId) {
		return getSectionSimple(sectionRelationId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
