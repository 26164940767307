<template>
	<div>
		<div class="is-clickable" @click="showPopup">
			<div class="is-flex is-flex-direction-column is-align-items-center has-text-centered">
				<div class="pb-5">
					<div class="is-relative">
						<img
							:src="achievement.badge_image_url"
							width="130"
							alt="achievement badge"
							:class="{ 'is-incomplete': achievedLevels === 0 }"
						/>

						<div
							v-for="starIndex in achievement.levels.length"
							:key="starIndex"
							:style="starStyle(starIndex, 3)"
						>
							<BaseIcon
								:color="
									achievedLevels >= starIndex
										? 'yellow-dark'
										: 'grey-light'
								"
								icon="star"
								:size="3"
								no-padding
							/>
						</div>
					</div>
				</div>
				<div class="progress-wrapper mx-auto">
					<BaseProgressBar
						v-if="progress < 100"
						class="progressbar my-4"
						:progress="progress"
						:color-name="achievement.color"
						height="small"
					/>
					<BaseCheckbox
						v-if="progress === 100"
						class="my-1"
						:active-color="achievement.color"
						:selected="true"
						:rounded="true"
					/>
				</div>
				<div class="is-size-6 is-family-secondary">{{ achievement.title }}</div>
				<div class="is-size-8">{{ achievement.description }}</div>
			</div>
		</div>

		<SplitModal
			ref="achievementModal"
			:image-width="5"
			:left-column-classes="[modalBackground, 'has-text-centered', 'has-fixed-img']"
		>
			<template #img>
				<div class="my-6 mx-auto pb-6" style="width: 250px">
					<div class="is-relative">
						<img
							:src="achievement.badge_image_url"
							alt="achievement badge"
							:class="{ 'is-incomplete': achievedLevels === 0 }"
						/>

						<div
							v-for="starIndex in achievement.levels.length"
							:key="starIndex"
							:style="starStyle(starIndex, 8)"
						>
							<BaseIcon
								:color="
									achievedLevels >= starIndex
										? 'yellow'
										: 'grey-light'
								"
								icon="star"
								:size="8"
								no-padding
							/>
						</div>
					</div>
				</div>
				<div
					v-if="showAchievedPercentage"
					:class="{ 'is-incomplete': achievedLevels === 0 }"
					class="achieved-percentage-container is-size-6"
				>
					<BaseIcon :icon="achievedPercentageIcon" :size="7" class="mb-3 mx-auto" />
					{{ achievedPercentageText }}
				</div>
			</template>

			<template #body>
				<p class="title is-3 mb-0">{{ achievement.title }}</p>
				<p class="mb-5 is-flex is-align-items-center">
					{{ achievement.description }}
					<ExerciseIcon v-if="difficulty" class="ml-2" :type="exerciseIcon" />
				</p>
				<div class="mb-5">
					<div
						v-for="level in achievement.levels"
						:key="level.id"
						v-tippy="{
							content: levelTooltip(level),
							placement: 'bottom',
							onShow: () => showAchievedPercentage,
						}"
						class="columns is-vcentered is-gapless"
					>
						<div class="column is-2">
							<div class="is-flex is-align-items-center">
								<BaseIcon
									:color="
										isLevelCompleted(level)
											? 'yellow'
											: 'grey'
									"
									icon="star"
									:size="3"
									no-padding
								/>
							</div>
						</div>
						<div class="column is-relative">
							<a
								v-if="level.type === 'click_link'"
								:class="{ 'completed-step': isLevelCompleted(level) }"
								:href="level.string_value"
								target="_blank"
								@click="clickedLink(level.string_value)"
							>
								{{ getLevelText(level) }}
							</a>
							<a
								v-else-if="level.type === 'complete_exercise'"
								:class="{ 'completed-step': isLevelCompleted(level) }"
								@click.prevent="openExerciseLink(level)"
							>
								{{ getLevelText(level) }}
							</a>
							<span
								v-else
								:class="{ 'completed-step': isLevelCompleted(level) }"
							>
								{{ getLevelText(level) }}
							</span>
							<div style="position: absolute; width: 60%">
								<BaseProgressBar
									class="progressbar"
									:progress="getLevelProgress(level)"
									:color-name="achievement.color"
									height="mini"
								/>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template #footer>
				<div class="progress-wrapper">
					<BaseProgressBar
						class="progressbar"
						:progress="progress"
						:color-name="achievement.color"
					/>
					<BaseCheckbox
						v-if="progress === 100"
						:active-color="achievement.color"
						:selected="true"
						:rounded="true"
						class="ml-4"
					/>
				</div>
			</template>
		</SplitModal>
	</div>
</template>

<script>
import icon from '@/mixins/icon';
import BaseIcon from '@/components/base/BaseIcon';
import SplitModal from '@/components/ui/modal/SplitModal';
import BaseProgressBar from '@/components/base/BaseProgressBar';
import BaseCheckbox from '@/components/base/input/BaseCheckbox';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';
import Section from '@/models/section/Section';

export default {
	name: 'AchievementCard',
	components: {
		BaseIcon,
		SplitModal,
		BaseProgressBar,
		BaseCheckbox,
		ExerciseIcon,
	},
	mixins: [icon],
	props: {
		achievement: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			exercises: [],
		};
	},
	computed: {
		icon() {
			let filename = this.achievement.machine_name;

			if (this.achievement.step != 0) {
				return `${filename}-${this.achievement.step}.svg`;
			}

			return `${filename}.svg`;
		},
		exerciseIcon() {
			if (this.difficulty) {
				return this.getExerciseIcon(this.difficulty);
			}

			return null;
		},
		modalBackground() {
			return this.achievedLevels == 0
				? 'has-background-grey-lightest'
				: `has-background-${this.achievement.color}-dark`;
		},
		progress() {
			for (let i = 0; i < this.achievement.levels.length; i++) {
				const level = this.achievement.levels[i];
				const userAchievementLevel = level.user_achievement_level;
				if (!userAchievementLevel) {
					if (level.amount === null) {
						// if the amount of the level is null, then it is a binary level
						// in which case progress indicates the total level progress
						return Math.min(
							100,
							Math.floor(
								(this.achievedLevels / this.achievement.levels.length) *
									100 *
									100,
							) / 100,
						);
					} else {
						return 0;
					}
				} else if (userAchievementLevel.achieved_at === null) {
					let progress = 0;
					if (i === 0) {
						progress = (userAchievementLevel.amount / level.amount) * 100;
					} else {
						const prevLevel = this.achievement.levels[i - 1];
						if (
							prevLevel.type !== level.type ||
							prevLevel.difficulty !== level.difficulty
						) {
							progress = (userAchievementLevel.amount / level.amount) * 100;
						} else {
							progress =
								((userAchievementLevel.amount - prevLevel.amount) /
									(level.amount - prevLevel.amount)) *
								100;
						}
					}
					return Math.min(100, Math.floor(progress * 100) / 100);
				}
			}
			return 100;
		},
		achievedLevels() {
			const userAchievementLevels = this.achievement.levels.map(l => l.user_achievement_level);
			return userAchievementLevels.filter(ual => ual && ual.achieved_at !== null).length;
		},
		difficulty() {
			return Math.max.apply(
				Math,
				this.achievement.levels.map(level => level.difficulty),
			);
		},
		achievedPercentageIcon() {
			if (this.achievedPercentage <= 10) {
				return 'medal-a';
			} else if (this.achievedPercentage <= 50) {
				return 'medal-b';
			} else {
				return 'medal-c';
			}
		},
		achievedPercentage() {
			const lastAchievedLevel = this.achievement.levels
				.filter(
					l =>
						l.user_achievement_level !== null &&
						l.user_achievement_level.achieved_at !== null,
				)
				.pop();
			if (lastAchievedLevel) {
				return lastAchievedLevel.achieved_percentage;
			} else {
				return this.achievement.levels[0].achieved_percentage;
			}
		},
		showAchievedPercentage() {
			return this.achievement.levels.every(l => l.only_for !== 'teachers');
		},
		achievedPercentageText() {
			const achieved = this.achievedPercentage < 1 ? 'Under 1' : this.achievedPercentage;
			return `${achieved}% af brugerne på Minlæring har gennemført denne bedrift.`;
		},
	},
	methods: {
		showPopup() {
			this.achievement.levels.forEach(level => {
				if (level.type == 'complete_exercise') {
					this.loadExercise(level);
				}
			});
			this.$refs.achievementModal.show();
		},
		isLevelCompleted(level) {
			return level.user_achievement_level && level.user_achievement_level.achieved_at !== null;
		},
		starStyle(starIndex, size) {
			const degrees = 45 + this.achievement.levels.length * 10;
			const range = 2 * Math.PI * (degrees / 360);
			const offset = 2 * Math.PI * ((90 - degrees / 2) / 360);
			const factor = (starIndex - 0.5) / this.achievement.levels.length;
			// const narrowFactor = 0.6;
			const narrowFactor = 0.54 + this.achievement.levels.length * 0.02;
			const left = Math.round(Math.cos(offset + factor * range) * 100) * narrowFactor * -1;
			const top = Math.round(Math.sin(offset + factor * range) * 100 - 4);
			const halfWidth = ((size + 1) * 7) / 2;
			return {
				position: 'absolute',
				top: top + '%',
				left: `calc(${50 + left}% - ${halfWidth}px)`,
			};
		},
		getLevelText(achievementLevel) {
			let res = '';
			const map = {
				completed_exercises: `Løs ${achievementLevel.amount} øvelser`,
				complete_exercise: 'Løs øvelsen',
				points: `Optjen ${achievementLevel.amount} point`,
				minimum_points: `Få ${achievementLevel.value} point i ${achievementLevel.amount} øvelser`,
				completed_exercises_in_different_books: `Løs øvelser i ${achievementLevel.amount} forskellige bøger`,
				completed_exercises_in_different_screenings: `Løs øvelser i ${achievementLevel.amount} forskellige screeninger`,
				login_days: `${achievementLevel.amount} dage`,
				completed_homework: `Løs ${achievementLevel.amount} lektier`,
				change_profile_image: 'Skift profilbillede',
				fill_email: 'Udfyld mail',
				attach_uni_login: 'Tilknyt UNI-Login',
				click_link: 'Klik på link',
				homework_created_count: `Opret ${achievementLevel.amount} lektier`,
				screenings_created_count: `Opret ${achievementLevel.amount} screeninger`,
				search_count: `lave ${achievementLevel.amount} søgninger`,
				create_class: 'Opret hold',
				create_class_with_uni_login: 'Opret hold med UNI-Login',
				add_teacher_to_class: 'Del hold med kollega',
				add_student_to_class: 'Tilføj elev til hold',
				impersonate_student: 'Brug “vis som elev”',
				clicked_intro_video: 'Se velkomstvideo',
				clicked_editool_link: 'Tilføj editool',
				open_lix: 'Besøg ordbogen (Lix)',
				confirm_information: 'Bekræft oplysninger',
			};
			res = map[achievementLevel.type];
			switch (achievementLevel.difficulty) {
				case 1:
				case 2:
				case 3:
					res += ` på niveau ${achievementLevel.difficulty}`;
					break;
				case 4:
					res += ' eksamensopgaver';
					break;
			}
			return res;
		},
		getLevelProgress(level) {
			if (!level.user_achievement_level) {
				return 0;
			}
			if (level.amount === null || level.amount === 0) {
				return 100;
			} else {
				const res = Math.floor((level.user_achievement_level.amount / level.amount) * 100);
				return Math.min(100, res);
			}
		},
		clickedLink(link) {
			this.logActivity('clicked_link', link);
		},
		logActivity(action, link) {
			return this.$store.dispatch('activity/logActivity', {
				action: action,
				link: link,
			});
		},
		loadExercise(level) {
			this.loading = true;
			const id = level.value;
			this.$store
				.dispatch('sections/getSectionSimple', id)
				.then(({ section }) => {
					this.exercises.push({
						id: id,
						exercise: new Section(section),
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		openExerciseLink(level) {
			const exercise = this.exercises.find(ex => ex.id === level.value);

			if (exercise?.exercise?.path) {
				window.open(exercise.exercise.path, '_blank', 'noopener,noreferrer');
			}
		},
		levelTooltip(level) {
			const progress = this.getLevelProgress(level);
			const achieved = level.achieved_percentage < 1 ? 'Under 1' : level.achieved_percentage;
			return `${progress}% af niveau gennemført. ${achieved}% af Minlærings brugere har opnået dette niveau.`;
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.completed-step {
	text-decoration: line-through;
}
.progress-wrapper {
	display: flex;
	align-items: center;
}
.is-clickable .progress-wrapper {
	display: flex;
	align-items: center;

	.progressbar {
		width: 48px;
		margin-top: 0.2rem;
	}
}
.is-incomplete {
	filter: grayscale(80%);
	opacity: 0.4;
}
.achieved-percentage-container {
	border-radius: 30px;
	border: 3px solid $grey;
	background-color: $white;
	padding: 1.5rem;
	margin: 1.5rem 3rem;
}
</style>
