import http from './http';

export function getUser() {
	return http.get('/user').then(resp => resp.data.data);
}

export function getSchools() {
	return http.get('/user/schools').then(resp => resp.data.data);
}

export function updateProfile(payload) {
	return http.put('/user/profile', payload).then(resp => resp.data.data);
}

export function updatePassword(current_password, password, password_confirmation) {
	return http
		.put('/user/password', {
			current_password,
			password,
			password_confirmation,
		})
		.then(resp => resp.data.data);
}

export function getAvatars() {
	return http.get('/user/avatars').then(resp => resp.data);
}

export function getColors() {
	return http.get('/user/colors').then(resp => resp.data);
}

export function getAchievements() {
	return http.get('/user/achievements').then(resp => resp.data);
}

export function getFeaturedAchievements(params) {
	return http.get('/user/featuredAchievements', { params }).then(resp => resp.data);
}

export function getUserAchievementLevels(schoolId, userId) {
	return http.get(`/schools/${schoolId}/students/${userId}/achievements`).then(resp => resp.data);
}

export function getDashboard(schoolId) {
	return http.get(`/schools/${schoolId}/user/dashboard`).then(resp => resp.data.data);
}

export function getInsightItems(school_id) {
	return http.get(`/schools/${school_id}/user/dashboard/insightitems`).then(resp => resp.data.data);
}

export function getInsightGraphData(school_id, school_classes, answers, type, period) {
	return http
		.post(`/schools/${school_id}/user/dashboard/insightgraphdata`, {
			school_classes,
			answers,
			type,
			period,
		})
		.then(resp => resp.data.data);
}

export function ping() {
	return http.post('/user/ping');
}
