<template>
	<div class="has-text-centered">
		<div class="error-image-container mx-auto">
			<img src="/images/500.svg" alt="500.svg" />
		</div>
		<div class="error-delimiter has-background-grey-light mx-auto my-4"></div>
		<h1 class="title is-3 has-text-grey-dark">Skolen kunne ikke indlæses</h1>
		<p class="has-text-grey">
			Skolen kunne ikke indlæses. Kontakt support på support@minlaering.dk for at få hjælp.
		</p>
	</div>
</template>

<script>
export default {
	name: 'Locked',
	created() {
		// avoid showing lix token
		this.$store.dispatch('lix/setToken', { token: '' });
	},
};
</script>

<style lang="scss" scoped>
.error-image-container {
	max-width: 500px;
}

.error-delimiter {
	height: 0.25rem;
	border-radius: 0.5rem;
	width: 5rem;
}
</style>
