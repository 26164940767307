<template>
	<BaseCardContainer
		class="box is-rounded is-cliackable is-hoverable is-centered"
		image-container-height="18rem"
		content-container-height="2rem"
		@click.native="$emit('onClick')"
	>
		<template #image>
			<img v-if="imageUrl" :src="imageUrl" class="base-card-image is-rounded" />
			<BaseSkeleton
				v-else
				rounded
				width="100%"
				height="18rem"
				class="base-card-image"
				:class="backgroundColor"
				:animate="false"
			/>
		</template>
		<template #title>{{ title }}</template>
		<template #content>
			<div
				class="is-flex is-flex-direction-column is-justify-content-space-between has-text-centered"
			>
				<div>
					<BaseTag v-if="tag" size="normal" color="grey-lighterer" text-color="grey">
						{{ tag }}</BaseTag
					>
				</div>
			</div>
		</template>
	</BaseCardContainer>
</template>

<script>
import BaseCardContainer from '@/components/base/BaseCardContainer';
import BaseTag from '@/components/base/BaseTag';
import BaseSkeleton from '@/components/base/load/BaseSkeleton';

export default {
	name: 'FeaturedAchievementCardContainer',
	components: {
		BaseCardContainer,
		BaseTag,
		BaseSkeleton,
	},
	props: {
		backgroundColor: {
			type: String,
			default: '',
		},
		imageUrl: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		tag: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';
</style>
