<template>
	<div :class="{ overlay: isMaintenance }">
		<component
			:is="errorComponent"
			v-if="errorComponent"
			:err="firstError"
			:is-maintenance="isMaintenance"
		/>
		<slot v-else></slot>
	</div>
</template>

<script>
import Router from 'vue-router';
import {
	ExtendableError,
	NotFoundError,
	UnauthorizedError,
	RequestAbortedError,
	ServerError,
	LockedError,
} from '@/api/errors';
import { shouldErrorStopPropagation } from '@/utils/error';
import NotFound from '@/components/errors/NotFound';
import Locked from '@/components/errors/Locked';
import ServerErrorComponent from '@/components/errors/ServerError';

import axios from 'axios';
import store from '@/store';

const http = axios.create({
	timeout: 2000,
});

http.interceptors.request.use(config => {
	config.baseURL = store.state.app.config.apiURL;
	return config;
});

export default {
	name: 'ErrorListener',
	components: {
		NotFound,
		Locked,
		ServerError: ServerErrorComponent,
	},
	data() {
		return {
			errors: [],
			apiDown: false,
		};
	},
	computed: {
		errorComponent() {
			if (this.firstError instanceof NotFoundError) {
				return 'NotFound';
			}
			if (this.firstError instanceof LockedError) {
				return 'Locked';
			}
			if (this.firstError instanceof ServerError && this.apiDown) {
				return 'ServerError';
			}
			return null;
		},
		firstError() {
			return this.errors.length ? this.errors[0] : null;
		},
		hasMultipleErrors() {
			return this.errors.length > 1;
		},
		isMaintenance() {
			return (
				this.firstError instanceof ServerError &&
				this.apiDown &&
				this.firstError?.status === 503
			);
		},
	},
	watch: {
		$route() {
			this.reset();
		},
	},
	created() {
		window.addEventListener('unhandledrejection', this.onUnhandledPromiseRejection);
	},
	beforeDestroy() {
		window.removeEventListener('unhandledrejection', this.onUnhandledPromiseRejection);
	},
	methods: {
		async onUnhandledPromiseRejection(event) {
			// event is a PromiseRejectionEvent
			// See: https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent

			const err = event.reason;

			if (shouldErrorStopPropagation(err)) {
				event.stopPropagation();
				event.preventDefault();
			}

			if (shouldIgnore(err)) {
				return;
			}

			if (err instanceof ServerError) {
				const up = await checkBackendHealth();
				if (!up) {
					// eslint-disable-next-line no-console
					console.log('API is down');
					this.apiDown = true;
					this.errors.push(err);
					this.checkBackendHealthPeriodically();
					return;
				}
			}

			this.errors.push(err);

			if (!(err instanceof LockedError) && (!this.errorComponent || this.hasMultipleErrors)) {
				this.$toasted.show(err.message, {
					position: 'top-center',
					type: 'error',
					duration: 5000,
				});
			}
		},
		checkBackendHealthPeriodically() {
			const checkFunc = async () => {
				const up = await checkBackendHealth();
				if (up) {
					// eslint-disable-next-line no-console
					console.log('API is back up');
					window.location.reload();
					return;
				}

				setTimeout(checkFunc, 1000 * 60);
			};

			setTimeout(checkFunc, 1000 * 60);
		},
		reset() {
			this.apiDown = false;
			this.errors = [];
		},
	},
};

function shouldIgnore(err) {
	return (
		!(err instanceof ExtendableError) ||
		err instanceof UnauthorizedError ||
		err instanceof RequestAbortedError ||
		Router.isNavigationFailure(err)
	);
}

async function checkBackendHealth(count = 1, retries = 3) {
	return http
		.get('/status')
		.then(() => true)
		.catch(() => {
			if (count < retries) {
				return delay(count).then(() => checkBackendHealth(count + 1, retries));
			}
			return false;
		});
}

function delay(count = 1) {
	return new Promise(resolve => setTimeout(resolve, 200 * (1 << (count - 1))));
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999;
	background-color: $grey-lighterer;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
