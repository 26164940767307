<template>
	<div class="is-flex is-justify-content-space-between is-align-items-center mt-2 device-responsive-flex">
		<div class="action">
			<a @click.prevent="openLink('https://support.minlaering.dk/inspiration-til-undervisningen')">
				<ShortcutCard
					title="Inspiration"
					subtitle="Få fuldt udbytte af Minlæring"
					image-url="/images/dashboard/light_bulb_on.svg"
					image-padding="3"
					background-color="has-background-purple-soft"
					description="21 forslag til at bruge Minlæring i undervisningen"
				/>
			</a>
		</div>
		<div class="action">
			<div style="cursor: pointer !important">
				<ShortcutCard
					title="Adaptiv træning"
					subtitle="Adaptive øvelser"
					image-url="/images/dashboard/pulldown_machine.svg"
					image-padding="1"
					background-color="has-background-yellow-soft"
					description="Sæt eleverne i gang med en adaptiv træning"
					@onClick="toggleAdaptiveTrainingPopUp"
				/>
			</div>
			<div>
				<AdaptiveTrainingModal
					v-if="showAdaptiveTrainingModalPopup"
					@hide="toggleAdaptiveTrainingPopUp"
				/>
			</div>
		</div>
		<div class="action">
			<router-link :to="{ name: 'school_classes_all' }">
				<ShortcutCard
					title="Vikartime"
					subtitle="Administration af hold"
					image-url="/images/dashboard/school_bag.svg"
					image-padding="3"
					background-color="has-background-green-soft"
					description="Arbejd med et af dine kollegers hold"
				/>
			</router-link>
		</div>
		<div class="action">
			<a @click.prevent="openLink('https://www.editool.dk/')">
				<ShortcutCard
					title="Editool"
					subtitle="Digitalt retteprogram"
					image-url="/images/dashboard/laptop_open.svg"
					image-padding="2"
					background-color="has-background-blue-lighter"
					description="Få glæde af Editool i dit rettearbejde i Word og Docs"
				/>
			</a>
		</div>
	</div>
</template>

<script>
import ShortcutCard from './ShortcutCard';
import AdaptiveTrainingModal from '@/components/dashboard/AdaptiveTrainingModal.vue';

export default {
	name: 'Shortcuts',
	components: {
		ShortcutCard,
		AdaptiveTrainingModal,
	},
	data() {
		return {
			showAdaptiveTrainingModalPopup: false,
		};
	},
	methods: {
		toggleAdaptiveTrainingPopUp() {
			this.showAdaptiveTrainingModalPopup = !this.showAdaptiveTrainingModalPopup;
		},
		openLink(link) {
			window.open(link, '_blank', 'noopener,noreferrer');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';
.device-responsive-flex {
	@include mixin-mobile-only {
		flex-direction: column;
	}
}

.action {
	@include mixin-tablet-up {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	@include mixin-mobile-only {
		margin-bottom: 1.5rem;
	}
}
</style>
