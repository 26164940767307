<template>
	<div class="base-card">
		<div class="base-card-image-container" :style="{ height: imageContainerHeight }">
			<slot name="image" />
		</div>
		<div class="base-card-data mt-1" :style="{ height: contentContainerHeight }">
			<div class="has-text-centered">
				<p class="title is-5 mb-1">
					<slot name="title" />
				</p>
				<slot name="content" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseCardContainer',
	props: {
		imageContainerHeight: {
			type: String,
			default: '14rem',
		},
		contentContainerHeight: {
			type: String,
			default: '7rem',
		},
	},
};
</script>
