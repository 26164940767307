<template>
	<BaseLoader v-if="loading" size="large" class="mt-6" />
	<div v-else>
		<a v-if="level.type == 'click_link'" @click.prevent="clickedLink(level.string_value)">
			<FeaturedAchievementCardContainer
				:title="levelTitle()"
				:tag="levelTag()"
				:image-url="linkImage"
				background-color="has-background-grey-lighter"
			/>
		</a>
		<a v-else @click.prevent="openLink(exerciseLink())">
			<FeaturedAchievementCardContainer
				:title="levelTitle()"
				:tag="levelTag()"
				:image-url="exerciseImage()"
				background-color="has-background-grey-lighter"
			/>
		</a>
	</div>
</template>

<script>
import BaseLoader from '@/components/base/BaseLoader';
import FeaturedAchievementCardContainer from '@/components/dashboard/FeaturedAchievementCardContainer';
import Section from '@/models/section/Section';
import designation from '@/mixins/designation';

export default {
	name: 'FeaturedAchievementCard',
	components: {
		BaseLoader,
		FeaturedAchievementCardContainer,
	},
	mixins: [designation],
	props: {
		level: {
			type: Object,
			required: true,
		},
		linkImage: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			section: null,
		};
	},
	computed: {},
	created() {
		if (this.level.type == 'complete_exercise') {
			this.loadExercise(this.level.value);
		}
	},
	methods: {
		openLink(link) {
			window.open(link, '_blank', 'noopener,noreferrer');
		},
		clickedLink(link) {
			this.logActivity('clicked_link', link);
			this.openLink(link);
		},
		logActivity(action, link) {
			return this.$store.dispatch('activity/logActivity', {
				action: action,
				link: link,
			});
		},
		levelTitle() {
			if (this.level.type == 'complete_exercise' && this.section) {
				const full = this.$designate(
					this.section.title
						.replace(/^Opgave \d+:\s*/, '')
						.replace(/^./, str => str.toUpperCase()),
				);
				return this.truncate(full);
			}
			if (this.level.type == 'click_link') {
				let url = this.level.string_value;
				// eslint-disable-next-line no-useless-escape
				let websiteName = url.replace(/^(?:https?:\/\/)?(?:www\.)?/, '').match(/^[^\/.]+/)[0];
				return this.truncate(websiteName.charAt(0).toUpperCase() + websiteName.slice(1));
			}
			return '';
		},
		truncate(full) {
			return full.length > 19 ? full.substring(0, 17) + '...' : full;
		},
		levelTag() {
			if (this.level.type == 'complete_exercise') {
				return 'Øvelse';
			}
			if (this.level.type == 'click_link') {
				return 'Klik link';
			}
		},
		exerciseImage() {
			if (this.section && this.section.image_url) {
				return this.section.image_url;
			}
			return this.linkImage;
		},
		exerciseLink() {
			if (this.section && this.section.path) {
				return this.section.path;
			}
		},
		loadExercise(id) {
			this.loading = true;
			this.$store
				.dispatch('sections/getSectionSimple', id)
				.then(({ section }) => {
					this.section = new Section(section);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
