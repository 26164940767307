<template>
	<div>
		<div class="is-flex is-align-items-center py-4 mt-5 mb-5">
			<BaseIcon icon="flame-2" color="red" no-padding has-transition />
			<div class="title is-5 ml-3">{{ title }}</div>
		</div>

		<BaseTabs v-model="activeTab">
			<BaseTab
				v-for="(course, index) in coursesDisplayed"
				:id="index.toString()"
				:key="index.toString()"
			>
				<template #title>{{ course.title }}</template>
			</BaseTab>
		</BaseTabs>
		<BaseTabsContainer :active="activeTab">
			<BaseTabContent
				v-for="(course, index) in coursesDisplayed"
				:id="index.toString()"
				:key="index.toString()"
			>
				<div
					class="is-flex is-align-items-center mt-2 device-responsive-flex"
					:class="[
						levelsUpper(courseAchievement(course).levels).length < 3
							? 'is-justify-content-center'
							: 'is-justify-content-space-between',
					]"
				>
					<div class="action px-2">
						<AchievementCard
							:achievement="courseAchievement(course)"
							class="box is-rounded no-box-shadow achievement-card is-flex is-justify-content-center is-align-items-center"
						/>
					</div>
					<div
						v-for="(level, ind) in levelsUpper(courseAchievement(course).levels)"
						:id="ind.toString()"
						:key="ind.toString()"
					>
						<div class="action">
							<FeaturedAchievementCard
								:level="level"
								:link-image="
									courseAchievement(course)
										.featured_achievement_relation
										.link_image.url
								"
							/>
						</div>
					</div>
				</div>
				<div
					v-if="levelsLower(courseAchievement(course).levels)"
					class="is-flex is-align-items-center mt-5 device-responsive-flex"
					:class="[
						levelsLower(courseAchievement(course).levels).length < 4
							? 'is-justify-content-center'
							: 'is-justify-content-space-between',
					]"
				>
					<div
						v-for="(level, ind) in levelsLower(courseAchievement(course).levels)"
						:id="ind.toString()"
						:key="ind.toString()"
					>
						<div class="action">
							<FeaturedAchievementCard
								:level="level"
								:link-image="
									courseAchievement(course)
										.featured_achievement_relation
										.link_image.url
								"
							/>
						</div>
					</div>
				</div>
			</BaseTabContent>
		</BaseTabsContainer>
	</div>
</template>

<script>
import AchievementCard from '@/components/dashboard/AchievementCard';
import BaseTabs from '@/components/base/tabs/BaseTabs';
import BaseTab from '@/components/base/tabs/BaseTab';
import BaseTabsContainer from '@/components/base/tabs/BaseTabsContainer';
import BaseTabContent from '@/components/base/tabs/BaseTabContent';
import BaseIcon from '@/components/base/BaseIcon';
import FeaturedAchievementCard from '@/components/dashboard/FeaturedAchievementCard';
import { mapGetters } from 'vuex';

export default {
	name: 'FeaturedAchievements',
	components: {
		BaseIcon,
		BaseTabs,
		BaseTab,
		BaseTabsContainer,
		BaseTabContent,
		FeaturedAchievementCard,
		AchievementCard,
	},
	props: {
		achievements: {
			type: Array,
			required: true,
		},
		courses: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			activeTab: null,
			loading: false,
		};
	},
	computed: {
		...mapGetters('user', ['user']),
		coursesDisplayed() {
			const coursesIdList = this.achievements.map(a => a.featured_achievement_relation.course_id);
			return this.courses.filter(course => coursesIdList.includes(course.id));
		},
		title() {
			return this.achievements[0].title;
		},
	},
	mounted() {
		if (this.courses.length > 0) {
			this.activeTab = '0';
		}
	},
	methods: {
		courseAchievement(course) {
			const achievement = this.achievements.filter(
				elem => elem.featured_achievement_relation.course_id == course.id,
			);
			return achievement ? achievement[0] : this.achievements[0];
		},
		levelsUpper(levels) {
			if (levels.length < 4) return levels;
			return levels.slice(0, Math.floor(levels.length / 2));
		},
		levelsLower(levels) {
			if (levels.length < 4) return [];
			return levels.slice(Math.floor(levels.length / 2), levels.length);
		},
		achievementLevelDetails(level) {
			switch (level.type) {
				case 'complete_exercise':
					return [level.value];
				case 'click_link':
					return [level.string_value];
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';
.underlined-title {
	border-bottom: 4px solid $grey-lighter;
}
.device-responsive-flex {
	@include mixin-mobile-only {
		flex-direction: column;
	}
}

.action {
	@include mixin-tablet-up {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	@include mixin-mobile-only {
		margin-bottom: 1.5rem;
	}
}

.achievement-card {
	position: relative;
	transition: all 250ms ease;
	overflow: hidden;
	min-width: 17rem;
	max-width: 18rem;
	height: 24rem;
	padding: 0.75rem 0.75rem 0.75rem 0.75rem;
	border-color: $grey-lighter;
	border-style: solid;
	background-color: $transparent;

	&.is-centered {
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
