<template>
	<MaterialCard
		:image="coverImage"
		:title="book.title"
		:description="$designate(book.description)"
		:progress="progress"
		:size="size"
		:clickable="clickable"
		:animate="animate"
		:disabled="disabled"
		:tooltip="tooltip"
	>
		<template v-if="hasTopRightSlot" #top-right>
			<slot name="top-right" />
		</template>
	</MaterialCard>
</template>

<script>
import designation from '@/mixins/designation';
import MaterialCard from '@/components/course/MaterialCard';

export default {
	name: 'BookCard',
	components: { MaterialCard },
	mixins: [designation],
	props: {
		book: {
			type: Object,
			required: true,
		},
		size: {
			type: String,
			default: 'normal',
			validator: value => ['small', 'normal', 'large'].includes(value),
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		animate: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		progress: {
			type: Number,
			default: undefined,
		},
		tooltip: {
			type: String,
			default: undefined,
		},
	},
	computed: {
		coverImage() {
			return !this.isRestricted && !this.disabled
				? this.book.cover_image
				: this.book.cover_no_access_image;
		},
		isRestricted() {
			return !!this.book.bundle_access?.is_restricted;
		},
		hasTopRightSlot() {
			return this.$slots['top-right'] !== undefined;
		},
	},
};
</script>
