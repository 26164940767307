import dayjs from 'dayjs';
import VueCookie from 'vue-cookie';
import {
	getAchievements,
	getFeaturedAchievements,
	getUserAchievementLevels,
	getAvatars,
	getColors,
	getDashboard,
	getInsightItems,
	getInsightGraphData,
	getSchools,
	getUser,
	ping,
	updatePassword,
	updateProfile,
} from '@/api/user';
import { cacheItem, forgetItem, getCachedItem } from '../cache';
import { cookieOptions } from '../cookie';
import { hasMultipleSchools, hasRole } from '@/utils/user';

const userKey = 'user';
const schoolKey = 'schoolId';
const activityKey = 'user-activity';
const provideEmailSkippedKey = 'provide-email-skipped';

const state = () => ({
	user: getCachedItem(userKey) || null,
	schoolId: VueCookie.get(schoolKey) || null,
	activity: VueCookie.get(activityKey) || null,
	provideEmailSkipped: !!VueCookie.get(provideEmailSkippedKey) || false,
	achievements: null,
	featuredAchievements: null,
});

const getters = {
	user: (state, _, rootState) => {
		if (rootState?.studentImpersonation?.user) {
			return rootState.studentImpersonation.user;
		}
		return state.user;
	},
	school: (state, getters) => getters.user?.schools?.find?.(s => s.id == state.schoolId) || null,
	isTeacher: (_, getters) => {
		if (!getters.school) return false;
		return hasRole(getters.school, 'teacher');
	},
	isStudent: (_, getters) => {
		if (!getters.school) return false;
		return hasRole(getters.school, 'student');
	},
	hasMultipleSchools: (_, getters) => {
		if (!getters.user) return false;
		return hasMultipleSchools(getters.user);
	},
	shouldSelectPreferredCourses: (state, getters) => {
		if (!state.user || !getters.isTeacher) {
			return false;
		}
		return !state.user.preferred_courses?.length && state.user.has_selected_preferred_courses !== true;
	},
	shouldProvideEmail: (state, getters) => {
		if (!state.user || !getters.isTeacher || state.user.email) {
			return false;
		}
		return !state.provideEmailSkipped;
	},
	schoolIsPrimarySchool: (state, getters) => {
		const school = getters.school;
		return school?.school_type?.machine_name == 'primary_school';
	},
	achievements: state => state.achievements,
	featuredAchievements: state => state.featuredAchievements,
};

let fetchAchievements = null;
let fetchFeaturedAchievements = null;
const actions = {
	setSchoolId({ commit }, { schoolId }) {
		return commit('setSchoolId', schoolId);
	},
	getUser({ commit, rootGetters }) {
		// Avoid reloading base user with student if studentImpersonation is active
		if (rootGetters['studentImpersonation/isActive']) {
			return null;
		}
		return getUser().then(user => {
			commit('setUser', user);
		});
	},
	updateProfile({ commit, dispatch, state, rootGetters }, payload) {
		return updateProfile(payload).then(user => {
			if (rootGetters['studentImpersonation/isActive']) {
				// Update studentImpersonation user if active
				dispatch('studentImpersonation/setUser', user, { root: true });
			} else {
				// Otherwise update authed user
				commit('setUser', user);
			}

			if (state.schoolId && payload.course_ids !== undefined) {
				// Reload books and assignments by course for the mega menus
				dispatch('books/getMenuBooks', { schoolId: state.schoolId }, { root: true });
				dispatch(
					'assignments/getAssignmentsByCourse',
					{ schoolId: state.schoolId },
					{ root: true },
				);
			}
			return user;
		});
	},
	updatePassword(_, { current_password, password, password_confirmation }) {
		return updatePassword(current_password, password, password_confirmation);
	},
	getAvatars() {
		return getAvatars();
	},
	getColors() {
		return getColors();
	},
	getAchievements({ commit }) {
		if (!fetchAchievements) {
			fetchAchievements = getAchievements().then(achievements => {
				// add latest achieved at to achievement
				achievements.data = achievements.data.map(achievement => {
					let latestAchievedAt = null;
					for (const level of achievement.levels) {
						if (level.user_achievement_level?.achieved_at) {
							if (
								latestAchievedAt === null ||
								level.user_achievement_level.achieved_at >
									latestAchievedAt
							) {
								latestAchievedAt =
									level.user_achievement_level.achieved_at;
							}
						}
					}
					achievement.latest_achieved_at = latestAchievedAt;
					return achievement;
				});
				commit('setAchievements', achievements.data);
			});
		}
		return fetchAchievements;
	},
	getFeaturedAchievements({ commit }, params = {}) {
		if (!fetchFeaturedAchievements) {
			fetchFeaturedAchievements = getFeaturedAchievements(params).then(achievements => {
				achievements.data = achievements.data.map(achievement => {
					let latestAchievedAt = null;
					for (const level of achievement.levels) {
						if (level.user_achievement_level?.achieved_at) {
							if (
								latestAchievedAt === null ||
								level.user_achievement_level.achieved_at >
									latestAchievedAt
							) {
								latestAchievedAt =
									level.user_achievement_level.achieved_at;
							}
						}
					}
					achievement.latest_achieved_at = latestAchievedAt;
					return achievement;
				});
				commit('setFeaturedAchievements', achievements.data);
			});
		}
		return fetchFeaturedAchievements;
	},
	getUserAchievementLevels({ rootState }, { user_id }) {
		return getUserAchievementLevels(rootState.user.schoolId, user_id);
	},
	clearAchievements() {
		fetchAchievements = null;
		fetchFeaturedAchievements = null;
	},
	getSchools() {
		return getSchools();
	},
	getDashboard({ rootState }) {
		return getDashboard(rootState.user.schoolId);
	},
	getInsightItems({ rootState }) {
		return getInsightItems(rootState.user.schoolId);
	},
	getInsightGraphData({ rootState }, { school_classes, answers, type, period }) {
		return getInsightGraphData(rootState.user.schoolId, school_classes, answers, type, period);
	},
	ping({ commit, rootState, rootGetters }) {
		if (rootState.auth.isImpersonation || rootGetters['studentImpersonation/isActive']) {
			return null;
		}
		return ping().then(() => {
			commit('setActivity', dayjs());
		});
	},
};

const mutations = {
	setUser(state, user) {
		state.user = user;
		cacheItem(userKey, user);
	},
	clearUser(state) {
		state.user = null;
		forgetItem(userKey);
	},
	setSchoolId(state, schoolId) {
		VueCookie.set(schoolKey, schoolId, cookieOptions(true));
		state.schoolId = schoolId;
	},
	setActivity(state, activityTime) {
		VueCookie.set(activityKey, activityTime, cookieOptions());
		state.activity = activityTime;
	},
	clearSchool(state) {
		VueCookie.delete(schoolKey);
		state.schoolId = null;
	},
	skipProvideEmail(state) {
		VueCookie.set(provideEmailSkippedKey, true, cookieOptions(true));
		state.provideEmailSkipped = true;
	},
	setAchievements(state, achievements) {
		state.achievements = achievements;
	},
	setFeaturedAchievements(state, featuredAchievements) {
		state.featuredAchievements = featuredAchievements;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
