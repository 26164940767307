import http from './http';

export function getSectionWithContext(sectionRelationId) {
	return http
		.get(`/sectionrelations/${sectionRelationId}/sectionwithcontext`)
		.then(({ data }) => ({ section: data.data, ...data.meta }));
}

export function getSectionSimple(sectionRelationId) {
	return http
		.get(`/sectionrelations/${sectionRelationId}/sectionsimple`)
		.then(({ data }) => ({ section: data.data, ...data.meta }));
}
